import React, { useEffect, useState } from 'react'
import {  LogoutIcon } from '@heroicons/react/outline'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import { signOutURL } from '../utils/Urls'
import { header } from '../utils/Request'

export default function NavBar() {
const history = useHistory()

const [path,setPath] = useState()
    useEffect(()=>{
        setPath(history.location.pathname)
    },[history.location.pathname])

    const logoutUser =async () =>{
      try {
        let data = await  axios.post(signOutURL,{
            "phone_number": localStorage.getItem('mobileNumber'),
          },{
            withCredentials: true,
            headers:header 
          })
          if (data.data.Status === "Success") {
              history.push('/')
              window.location.reload()
          } else {
            console.log("error from line 37")
            alert(data.data.Message)
           
          }
         
      } catch (error) {
        alert(error) 
      }
}
    return (
        <div className='min-w-full shadow bg-white' > 
           <div className='container m-auto'>
               <div className='flex justify-between' style={{minHeight:'60px'}}>
                   <div className='my-auto'>
                   <img src={"https://adityatrading.in/static/images/logo.png"} className='p-2' onClick={()=>history.push('/')}/>
                   </div>
                   <div className='my-auto flex justify-evenly'>
                       <p onClick={()=>history.push('/account')} className={path === '/account' ? 'mx-1 py-1 cursor-pointer bg-green-400 px-2 rounded text-white': 'mx-1 py-1 cursor-pointer hover:bg-green-400 px-2 rounded hover:text-white'}>Account</p>
                      <div className='bg-red-50 flex ml-2 rounded px-2 py-1 cursor-pointer'>
                      <p className='mx-1 text-red-500 text-sm' onClick={()=>logoutUser()}>Logout</p>
                       <LogoutIcon className="h-5 w-5 text-red-500 my-auto"/>
                      </div>
                   </div>
                   
               </div>
           </div>
        </div>
    )
}
