import React, { useState } from 'react'
import giftbox from './assets/giftbox.png'
import axios from 'axios'
import { checkOTPURL, sendOTPURL } from '../utils/Urls'
import { useHistory } from 'react-router'
import Loader from "react-loader-spinner";
import { header } from '../utils/Request'
export default function Login() {
    const history = useHistory()

    const [phone,setPhone] = useState("")

    const [error,setError] = useState("")
    const [isLoading,setLoading] = useState(false)

    const sendOTP = async() => {
        setError()
       if(phone !== ""){
        if(phone.length === 10){
            setLoading(true)
            try {
      
                let data =  await axios.post(sendOTPURL, {
                        "phone_number": phone,
                      }, {
                        
                        headers: header})
                      if(data.data.Status === "Success"){
                          console.log(data.data)
                          history.push({
                            pathname: '/verifyOTP',
                            state: { phone: phone }
                          })
                          setLoading(false)
                      }else{
                        setError(data.data.Message)
                        setLoading(false)
                      }
    
                } catch (error) {
                    setLoading(false)
                    setError(error.toString())
                }
    
        }else{
            setError("Please enter valid phone number"+phone)
        }


       }else{
        setError("Please enter valid phone number")
       }
    }

  


    return (
        <div className='container'>
                
                <img src={"https://adityatrading.in/static/images/logo.png"} className='p-2 ml-6 mt-3' onClick={()=>history.push('/')}/>
            
            <div className='lg:flex md:flex justify-center   sm:flex-none md:mt-12 mt-12'  style={{minHeight:'80vh'}}>
            
            <div className='m-auto flex-1'>
                   <img src={giftbox}  className='img-fluid mx-auto'  />
               </div>

               <div className='m-auto flex-1 p-4' style={{maxWidth:'460px'}}>
               <p className='font-bold text-2xl'>Refer a friend !</p>
                   <p className='mt-1 text-xl text-gray-500'>Simply refer a friend and get 30% brokerage of every trade your friend does.</p>
                   <div className='border mt-2 text-center'>
                     <div className='flex p-2'>
                       
                       <p className='mr-2'>+91 </p>
                 <input placeholder='Mobile Number' className='outline-none border-none' type='number' value={phone} onChange={(e)=>setPhone(e.target.value)}  maxLength={10}  />

                         </div>
                         </div>


                  
{error ? 
<div className='bg-red-200 p-2 text-sm mt-2 rounded'>
    {error}
</div>
:<div>
    
    </div>
}

{isLoading ? 
  <div className='flex justify-center'>
        <Loader
    type="ThreeDots"
    color="#00e676"
    height={60}
    width={60}//3 secs
    />
      </div>
      :

      <button  className='bg-green-500 hover:bg-green-400  px-4 py-2  text-white rounded mt-3 min-w-full' onClick={()=>sendOTP()}>Get OTP</button>
}


               </div>
            </div>



            {/* <div className='row' style={{minHeight:'100vh'}}>
               <div className='col-lg-6 my-auto'>
                   <img src={giftbox}  className='img-fluid m-auto' />
               </div>

               <div className='col-lg-6 lg:text-left my-auto' style={{maxWidth:'480px'}}>
                   <p className='font-bold text-2xl'>Refer a friend !</p>
                   <p className='mt-1 text-xl text-gray-500'>Simply refer a friend and get 30% brokerage of every trade your friend does.</p>

                   <p className='mt-3'>YOUR MOBILE NUMBER</p>

                     <div className='border mt-2 text-center'>
                     <div className='flex p-2'>
                       
                       <p className='mr-2'>+91 </p>
                 <input placeholder='MobileNumber' type='number'  maxLength='10'  />
                
                  
                         </div>
                         </div>
                         <button  className='bg-green-500 hover:bg-green-400  px-4 py-2  text-white rounded mt-3'>Get OTP</button>
                   
               </div>
           </div> */}
        </div>
    )
}
