
import React from 'react';
import { Bar } from 'react-chartjs-2';

const rand = () => Math.round(Math.random() * 20 - 10);



function MultiType({data}) {


    return(
   <div className='flex-1'>
 <div className='header mt-6 mx-6'>
    <h1 className='font-bold text-lg'>Brokerage vs Commission Overtime</h1>
    
  </div>
  <Bar data={data} />
   </div>
    )
    }

export default MultiType;