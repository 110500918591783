import React, { useEffect, useState } from 'react'
import giftbox from './assets/giftbox.png'
import axios from 'axios'
import { checkOTPURL, sendOTPURL } from '../utils/Urls'
import {  ArrowLeftIcon} from '@heroicons/react/solid'
import { useHistory } from 'react-router';

import Loader from "react-loader-spinner";
import OtpInput from 'react-otp-input';
import PageNotFound from './PageNotFound'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { header } from '../utils/Request';

export default function VerifyOTP(props) {
    const [otp, setOTP] = useState("")
    const history = useHistory()
    const [error, setError] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [isComplete , setComplete] = useState(false)

    
    const sendOTP = async() => {
        setError()
       if(props.location.state.phone !== ""){
        if(props.location.state.phone.length === 10){
            setLoading(true)
            try {
                let data =  await axios.post(sendOTPURL, {
                        "phone_number": props.location.state.phone,
                      },{headers:header})
                      if(data.data.Status === "Success"){
                          console.log(data.data)
                        alert("otp has been resent")
                          setLoading(false)
                      }else{
                        setError(data.data.Message)
                        setLoading(false)
                      }
    
                } catch (error) {
                    setLoading(false)
                    setError(error.toString())
                }
    
        }else{
            setError("Please enter valid phone number"+props.location.state.phone)
        }


       }else{
        setError("Please enter valid phone number")
       }
    }



    const submitOTP = async () => {
        setLoading(true)

        if (otp !== "") {
            if (otp.length === 6) {
                try {
                    
      
      
                    let data = await axios.post(checkOTPURL, {
                        "phone_number": props.location.state.phone,
                            "otp": otp
                    }, {
                        headers:header 
                      })

                    if (data.data.Status === "Success") {
                        console.log(data.data)
                        
                        localStorage.setItem("mobileNumber",props.location.state.phone)
                        setLoading(false)
                        history.replace('/')
                        window.location.reload()
                        
                    } else {
                        console.log("error from line 75")
                        setError(data.data.Message)
                        setLoading(false)
                    }

                } catch (error) {
                    
                    console.log("error from line 84")
                    setLoading(false)
                    setError(error.toString())
                }

            } else {
                setLoading(false)
                if (otp.length !== 6) {
                    setError("Please enter valid OTP")
                } else {
                    setError("Please enter valid OTP")
                }
            }
        } else {
            setLoading(false)
            if (otp === "") {
                setError("Please enter valid OTP")
            } else {
                setError("Please enter valid Phone Number")
            }
        }
    }

    useEffect(()=>{

        console.log(props?.location?.state?.phone)
    },[])

    return (
        <div className='container' style={{ minHeight: '90vh' }}>
            
               <img src={"https://adityatrading.in/static/images/logo.png"} className='p-2 ml-6 mt-3' onClick={()=>history.push('/')}/>
            

            {props?.location?.state?.phone ?    
        
        <div className='lg:flex md:flex justify-center min-h-screen sm:flex-none md:mt-12 '>
        <div className='m-auto flex-1'>
            <img src={giftbox} className='img-fluid mx-auto' />
        </div>

        <div className='m-auto flex-1 p-4' style={{ maxWidth: '460px' }}>
            <div className='flex'>
                <ArrowLeftIcon className='h-6 mr-2' onClick={()=>history.push('/')}/>

            <p className='font-bold text-2xl'>VERIFY OTP</p>
            </div>
            
            <p className='mt-1 text-l text-gray-500'>Enter your 6 digit OTP sent to your Mobile Number</p>
            <OtpInput
value={otp}
onChange={setOTP}
numInputs={6}
className='mt-2'
inputStyle={{
    width: "30px",
    height: "30px",
    margin: "0 0.5rem",
    fontSize: "16px",
    borderRadius: 4,
    border: "1px solid rgba(0,0,0,0.3)"
  }}
  
/>
{!isComplete ? 
<div className='flex mt-3'>
<p className='my-auto'>Resend OTP in &nbsp;</p>
<CountdownCircleTimer
    isPlaying
    duration={30}
    colors={[
      ['#004777', 0.33],
      ['#F7B801', 0.33],
      ['#A30000', 0.33],
    ]}
    
    onComplete={()=>{
        setComplete(true)
    }}

  size='35'
  strokeWidth='4'
  >
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>
  <p className='my-auto'>&nbsp; &nbsp; seconds</p>
  </div>
  :<p className='p-1 mt-3 text-green-500' onClick={()=>sendOTP()}>Resend OTP</p>
}


            {error ?
                <div className='bg-red-200 p-2 text-sm mt-2 rounded'>
                    {error}
                </div>
                : <div>

                </div>
            }


{isLoading ? 
<div className='flex justify-center'>
<Loader
type="ThreeDots"
color="#00e676"
height={60}
width={60}//3 secs
/>
</div>
:
<button className='bg-green-500 hover:bg-green-400  px-4 py-2  text-white rounded mt-3 min-w-full' onClick={() => submitOTP()}>Submit OTP</button>

}
        
                        </div>
    </div>



:<PageNotFound />
        }
        </div>
    )
}
