import React from 'react'

import {  PaperAirplaneIcon } from '@heroicons/react/solid'

import { useHistory } from 'react-router'
export default function PageNotFound() {
    const history = useHistory();
    
    return (
        <div className='min-h-screen'>
           <div className='mt-8 text-center'>
           <PaperAirplaneIcon className="h-56 text-gray-300 m-auto"/>
           <p className='mx-auto text-center text-gray-400'>Sorry you redirected to a wrong page !</p>

           <button className='bg-green-400 p-2 text-white rounded mt-3' onClick={()=>history.push('/')}>  Go to Home</button>
           </div>
           
        </div>
    )
}
