const apiBaseURL = "https://referral.adityatrading.in/api/" 
export const sendOTPURL = apiBaseURL+ "generate-one-time-password/"
export const checkOTPURL = apiBaseURL+ "check-one-time-password/"
export const getDataUrl = apiBaseURL+ "get-referral/"
export const getPaymentURL = apiBaseURL+ "get-payment/"
export const getPaymentProfileURL = apiBaseURL+ "get-payment-profile/"
export const getProfileURL = apiBaseURL+ "get-profile/"
export const getHistoricalReferralGraphURL = apiBaseURL+ "get-historical-referral-data/"
export const getReferralURL = apiBaseURL+ "get-referral/"
export const checkUserSessionURL ="https://referral.adityatrading.in/api/check-user-session/"
export const baseUrl = "https://kyc.adityatrading.in/referral/"
export const updateUserProfileURL = apiBaseURL+ "update-profile/"
export const updatePaymentProfileURL = apiBaseURL+ "update-payment-profile/"
export const updatePaymentURL = apiBaseURL+ "update-payment/"
export const signOutURL = apiBaseURL+ "signout-user/"

export const whatsappShareBaseURL = "https://api.whatsapp.com/send?text="
export const fbShareBaseURL = "https://www.facebook.com/sharer.php?u="
export const twitterShareBaseURL = "https://twitter.com/intent/tweet?url="
export const twitterShareEndURL ="&text=Referral%20Link"    