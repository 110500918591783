import React, { useEffect, useState } from 'react'

import { UsersIcon, ArrowSmUpIcon, CurrencyRupeeIcon, ReceiptTaxIcon, CreditCardIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import axios from 'axios'
import { baseUrl, fbShareBaseURL, getHistoricalReferralGraphURL, getPaymentURL, getProfileURL, getReferralURL, twitterShareBaseURL, twitterShareEndURL, whatsappShareBaseURL } from '../utils/Urls'
import { FaWhatsapp, FaFacebookF, FaTwitter } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import DataChart from '../components/DataChart';
import {  header } from '../utils/Request';

import { WithdrawModal } from '../modals/WithdrawModal';
import toast from 'react-hot-toast';


const rand = () => Math.round(Math.random() * 20 - 10)

export default function Dashboard() {
  
  const [userData, setUserData] = useState()
  const [Clients, setClients] = useState()
  const [brokerage, setBrokerage] = useState()
  const [commission, setCommission] = useState()
  const [wallet, setWallet] = useState("")
  const [paymentStatus,setPaymentStatus] = useState("")
  const [referralLink, setReferralLink] = useState()
  const [refferalDetails, setReferralDetails] = useState([])
  const [historicalData, setHistoricalData] = useState([])
  const [seriesA,setSeriesA] = useState([])
  const [seriesB,setSeriesB] = useState([])
  const [labels,setLabels] = useState([])

  useEffect(() => {
    getData()
  getHistoricalData()
getPayment()

  }, [])

  const getPayment = async () =>{

    try {
      let data = await axios.post(getPaymentURL, {
        "phone_number": localStorage.getItem('mobileNumber'),
      })
      if (data.data.Status === "Success") {
        console.log(data.data)
        if(data.data.Payment_Detail_Available === true){
          console.log(data.data)
          
          setWallet(parseFloat(data?.data?.Wallet_Amount).toFixed(2) ?? 0)
          setPaymentStatus(data.data.Payment_Status ?? "...")
        }else{
          setWallet(0)
        }
      } else {
        console.log("error data historical")
        console.log(data.data)
      }
    } catch (error) {
      console.log(error)
      console.log("hitrical data error")
    }

  }
  const getHistoricalData = async () => { 

    console.log("initiating historical data ")
    try {
      let data = await axios.post(getHistoricalReferralGraphURL, {
        "phone_number": localStorage.getItem('mobileNumber'),
      })
      if (data.data.Status === "Success") {
        console.log("value hist")
        console.log(data.data)
        setHistoricalData(data.data['Historical_Referral_Details'])
        const array1 = []
        const array2 = []
        const labels = []

        data.data['Historical_Referral_Details']?.map((item)=>{
          labels.push(item.date)
          array1.push(item.brokerage)
          array2.push(item.commission)

        })

        setLabels(labels)
  
        setSeriesA(array1)
        setSeriesB(array2)

      } else {
        console.log("error data historical")
        console.log(data.data)
      }
    } catch (error) {
      console.log(error)
      console.log("hitrical data error")
    }
  }
  //     var convertToINR = (rs) =>{
  //     var lastThree = rs.substring(rs.length-3);
  //     var otherNumbers = rs.substring(0,rs.length-3);
  //     if(otherNumbers != '')
  //         lastThree = ',' + lastThree;
  //     var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  //     return res
  // }

  const getData = async () => {
    try {
      let data = await axios.post(getReferralURL, {
        "phone_number": localStorage.getItem('mobileNumber'),
      },{
        withCredentials: true,
        headers:header 
      })
      if (data.data.Status === "Success") {

        setUserData(data.data)
        console.log(data.data)

        setClients(data.data?.Clients ?? 0)
        setBrokerage(data.data?.Total_Brokerage.toString() ?? "0")
        setCommission(data.data?.Total_Commission.toString() ?? "0")
      
        setReferralLink(baseUrl + data.data?.Referral_Link ?? baseUrl)

        setReferralDetails(data.data?.Referral_Details)

      } else {
        console.log(data.data)
      }
    } catch (error) {
    }
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    toast.success('Link copied to clipboard!');
     
  }

  return (

    <div>
      <NavBar />

      <div className='mt-10 shadow rounded-t-2xl bg-white min-h-screen p-4'>

        <div className='container mx-auto'>
          <div className='lg:flex sm:flex-none md:flex-none'>

            <div className='rounded p-4 shadow flex-1 m-2'>
              <div className='flex justify-between'>
                <div className=''>
                  <p className='font-medium text-gray-600'> C L I E N T S</p>
                  <p className='text-2xl font-bold'>{Clients ?? "..."}</p>

                  <div className='flex mt-4'>
                    <ArrowSmUpIcon className='h-5 text-green-500' />
                    <p className='text-green-500 text-sm'> Since Last Update</p>
                  </div>

                </div>

                <UsersIcon className='h-12 rounded-full bg-gray-100 p-2 text-gray-400 ' />

              </div>

            </div>

            <div className='rounded p-4 shadow flex-1 m-2'>
              <div className='flex justify-between'>
                <div className=''>
                  <p className='font-medium text-gray-600'>B R O K E R A G E</p>
                  <p className='text-2xl font-bold'>{brokerage ?? "..."}</p>

                  <div className='flex mt-4'>
                    <ArrowSmUpIcon className='h-5 text-green-500' />
                    <p className='text-green-500 text-sm'> Since Last Update</p>
                  </div>

                </div>

                <CurrencyRupeeIcon className='h-12 rounded-full bg-gray-100 p-2 text-gray-400 ' />

              </div>

            </div>
            <div className='rounded p-4 shadow flex-1 m-2'>
              <div className='flex justify-between'>
                <div className=''>
                  <p className='font-medium text-gray-600'>C O M M I S S I O N</p>
                  <p className='text-2xl font-bold'>{commission ?? "..."}</p>

                  <div className='flex mt-4'>
                    <ArrowSmUpIcon className='h-5 text-green-500' />
                    <p className='text-green-500 text-sm'> Since Last Update</p>
                  </div>

                </div>

                <ReceiptTaxIcon className='h-12 rounded-full bg-gray-100 p-2 text-gray-400 ' />

              </div>
            </div>
            <div className='rounded p-4 shadow flex-1 m-2'>
              <div className='flex justify-between'>
                <div className=''>
                  <p className='font-medium text-gray-600'> W A L L E T </p>
                  <p className='text-2xl font-bold'>{wallet ?? "..."}</p>

                  {paymentStatus === "Processing" ?
                   <p className='text-yellow-500 text-sm'>Payment Request Initiated</p>
                :
                wallet ?
                wallet > 0 ?
                <WithdrawModal amount={wallet} />
                :<div />
                :<div /> 
        
                }
                </div>

                <CreditCardIcon className='h-12 rounded-full bg-gray-100 p-2 text-gray-400 ' />

              </div>
            </div>
          </div>
        </div>
        {
          historicalData.length !== 0 ?
<div className='lg:flex md:flex-auto sm:flex-none'>
<DataChart data={
           {
            labels: labels,
            datasets: [
              {
                type: 'line',
                label: 'Brokerage',
                borderColor: 'rgb(54, 162, 235)',
                borderWidth: 2,
                fill: false,
                data: seriesA,
              },
              {
                type: 'line',
                label: 'Commission',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                data: seriesB,
              },
             
            ],
           }
          }/>

<div className='shadow p-4 mx-6 mt-4 flex-1'>
          <p className='font-bold'>Brokerage and Commission Over Clients</p>
          <div className='overflow-x-auto'>
            <table className="table-auto mt-8 ml-1">
              <thead>
                <tr className='bg-gray-200 border border-gray-300'>
                  <th className="w-1/4 ... p-4 border-r border-gray-300">Client Code</th>
                  <th className="w-1/4 ... p-4 ">Brokerage</th>
                  <th className="w-1/4 ... p-4 border-l border-gray-300">Commission</th>
                </tr>
              </thead>
              <tbody>
                {refferalDetails?.map((item) => {
                  return (
                    <tr className='border border-gray-300'>
                      <td className='p-2 mx-auto text-center border-r border-gray-300'>{item?.client_code}<p>{item?.date}</p></td>
                      <td className='p-2 mx-auto text-center border-r border-gray-300'>{parseFloat(item?.brokerage).toFixed(2)}</td>
                      <td className='p-2 mx-auto text-center border-r border-gray-300'>{parseFloat(item?.commission).toFixed(2)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

</div>
        
            : <div />
        }
        <div className='shadow-md mx-6 mt-8 p-4'>
          <p className='font-bold'>Your Unique referral link</p>

          <div className='border p-2 mt-2 flex justify-between'>
            <p className='font-light text-sm overflow-x-hidden text-gray-400'>{referralLink}</p>
            <ClipboardCopyIcon className='h-6 text-gray-400 w-8 my-auto ml-1' onClick={() => copyToClipboard(referralLink)} />


          </div>

          <p className='font-bold mt-3'> Share on Social Media</p>

          <div className='flex mt-3 '>
            <div className='bg-green-500 rounded-full p-2 text-2xl mr-2'>
              <FaWhatsapp className='text-white' onClick={() => window.open(whatsappShareBaseURL + referralLink)} />
            </div>

            <div className='bg-blue-500 rounded-full p-2 text-2xl mx-1'>
              <FaFacebookF className='text-white' onClick={() => window.open(fbShareBaseURL + referralLink)} />
            </div>


            <div className='bg-blue-400 rounded-full p-2 text-2xl ml-2'>
              <FaTwitter className='text-white' onClick={() => window.open(twitterShareBaseURL + referralLink + twitterShareEndURL)} />
            </div>

          </div>


        </div>

   
      </div>
    </div>
  )
}
