
import './App.css';
import Login from './pages/Login';
import { useState,useEffect } from 'react';

import { BrowserRouter as Router, Switch,Route} from 'react-router-dom'
import Home from './pages/Home';
import VerifyOTP from './pages/VerifyOTP';
import Account from './pages/Account';
import { checkUserSessionURL } from './utils/Urls';
import axios from 'axios'
import {  header } from './utils/Request';
import toast, { Toaster } from 'react-hot-toast';

function App() {
  const [isUserLoggedIn,setUserLoggedIn] = useState()
  useEffect(() => {
    // let number = localStorage.getItem("mobileNumber")
    // if(number !== null){
    //   setUserLoggedIn(true)

    // }else{
    //   setUserLoggedIn(false)
    // }

    checkUserSession()

  },[])

  const checkUserSession = async () => {
    try {

      
      let body = {
        "phone_number": "9789896979"
      }
      

      let data = await axios.post(checkUserSessionURL, {
        "phone_number": localStorage.getItem('mobileNumber'),
      },{
        withCredentials: true,
        headers:header 
      })

      if (data.data.Status === "Success") {
        setUserLoggedIn(true)
      } else {
        console.log("error from line 37")
        setUserLoggedIn(false)
      }
    } catch (error) {
      console.log(error)
      setUserLoggedIn(false)
      console.log("error from 33")
      alert(error)
    }
  }
  
  return (
  <div>
     <Toaster />
      <Router>
    
      <Switch>
      <Route path="/" exact component={isUserLoggedIn ? Home :  Login}/>
      <Route path="/verifyOTP" component={VerifyOTP}/>
      <Route path="/account" component={Account}/>
      </Switch>
    </Router>
  </div>
  );
}

export default App;
