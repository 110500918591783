import React, { useEffect, useState } from 'react'

import Account from './Account'
import Dashboard from './Dashboard'
import PageNotFound from './PageNotFound'
import { useHistory } from 'react-router'
import NavBar from '../components/NavBar'
export default function Home() {
    const history = useHistory()
    const [location,setLocation] = useState()

    useEffect(()=>{
        setLocation(history.location.pathname)
    },[history.location.pathname])

    return (
        <div className='bg-gray-50'>
            
            
            {
                location ?
                location === '/'?
                <Dashboard />
                :location === '/account' ?
                <Account />
                :<PageNotFound />
                :<PageNotFound />
            }
        </div>
    )
}
